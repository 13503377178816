<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card v-if="isReady">
                <a-row :gutters="[16, 16]">
                    <a-col :span="24">
                        <a-month-picker
                            :default-value="currentDate"
                            :format="monthFormat"
                            v-model="currentDate"
                            @change="currentDateChange"
                        />
                        <a-divider type="vertical" />
                        <a-button type="primary" @click="exportExcel" :loading="exporting">
                            <a-icon type="export"></a-icon>
                            导出
                        </a-button>
                        <a-divider type="vertical" />
                        <a-button @click="getData">刷新</a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 32]">
                    <a-col>
                        <a-table
                            :columns="columns"
                            :data-source="tableData"
                            :defaultExpandedRowKeys="defaultExpandedRowKeys"
                            expandRowByClick
                            :pagination="false"
                        />
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { AppComponentBase } from '@/shared/component-base';
import moment from 'moment';
import { CustomizeReportServiceServiceProxy } from '@/shared/service-proxies/service-proxies';
import { fileDownloadService } from '@/shared/utils';

const columns = [
    {
        title: '部门',
        dataIndex: 'orgName',
        key: 'orgName',
    },
    {
        title: '月末',
        dataIndex: 'endMonthNum',
        key: 'endMonthNum',
    },
    {
        title: '入职人数',
        dataIndex: 'entryNum',
        key: 'entryNum',
    },
    {
        title: '离职人数',
        dataIndex: 'quitNum',
        key: 'quitNum',
    },
    {
        title: '月初人数',
        dataIndex: 'beginMonthNum',
        key: 'beginMonthNum',
    },
    {
        title: '月平均人数',
        dataIndex: 'averageNum',
        key: 'averageNum',
    },
    {
        title: '离职率',
        dataIndex: 'turnover',
        key: 'turnover',
        customRender: (text, row, index) => {
            return (text * 100).toFixed(2) + '%';
        },
    },
];

export default {
    name: 'employee-count',
    mixins: [AppComponentBase],
    data() {
        return {
            _customizeReportServiceServiceProxy: undefined,

            monthFormat: 'YYYY-MM',
            currentDate: undefined,

            columns,
            //表格数据
            tableData: [],
            //默认展开的行
            defaultExpandedRowKeys: [],
            //导出中
            exporting: false,
        };
    },
    computed: {
        zh_CN() {
            return zh_CN;
        },
    },
    mounted() {
        this._customizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);

        this.currentDate = moment();
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this._customizeReportServiceServiceProxy
                .getEmpNum(this.currentDate)
                .then(async (res) => {
                    this.defaultExpandedRowKeys = [];
                    this.tableData = await this.fetData(res);
                    this.isReady = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        //递归，判断所有项，当子项长度为0是，置空
        fetData(data) {
            return new Promise((resolve) => {
                for (let item of data) {
                    if (item.children.length === 0) {
                        item.children = undefined;
                    } else {
                        //只展开一级公司
                        if (item.parentID.indexOf('com-0') !== -1) {
                            this.defaultExpandedRowKeys.push(item.key);
                        }
                        this.fetData(item.children);
                    }
                }
                resolve(data);
            });
        },
        //日期改变
        currentDateChange(e) {
            this.currentDate = e;
            this.getData();
        },
        //导出excel
        exportExcel() {
            this.exporting = true;
            this._customizeReportServiceServiceProxy
                .getEmpNumToExcel(this.currentDate)
                .then((result) => {
                    fileDownloadService.downloadTempFile(result);
                })
                .finally(() => {
                    this.exporting = false;
                });
        },
    },
};
</script>

<style scoped></style>
